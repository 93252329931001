type props = {
  color: any;
};

export default function GpsIcon(props: props) {
  return (
    <div className="Icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        viewBox="0 0 349.661 349.661"
      >
        <defs>
          <linearGradient id="linearGradient2328">
            <stop offset="0" stopColor="#614ef0" stopOpacity="1"></stop>
            <stop offset="1" stopColor="#eb5df7" stopOpacity="1"></stop>
          </linearGradient>
          <linearGradient
            id="linearGradient2330"
            x1="42.849"
            x2="306.811"
            y1="174.831"
            y2="174.831"
            gradientUnits="userSpaceOnUse"
            xlinkHref="#linearGradient2328"
          ></linearGradient>
          <linearGradient
            id="linearGradient3118"
            x1="175.694"
            x2="175.478"
            y1="351.085"
            y2="37.847"
            gradientUnits="userSpaceOnUse"
            xlinkHref="#linearGradient2328"
          ></linearGradient>
        </defs>
        <g
          fill={props.color === "white" ? "white" : "url(#linearGradient2330)"}
          fillOpacity="1"
          transform="translate(-2.43 1.344)"
        >
          <path
            fill={props.color === "white" ? "white" : "url(#linearGradient3118)"}
            d="M174.831 0C102.056 0 42.849 59.207 42.849 131.981c0 30.083 21.156 74.658 62.881 132.485 30.46 42.215 61.363 76.607 61.671 76.95l7.429 8.245 7.429-8.245c.309-.342 31.211-34.734 61.671-76.95 41.725-57.828 62.881-102.402 62.881-132.485C306.812 59.207 247.605 0 174.831 0zm-.001 319.617C137.772 276.925 62.85 180.569 62.85 131.981 62.849 70.235 113.084 20 174.831 20c61.747 0 111.981 50.235 111.981 111.981 0 48.559-74.924 144.934-111.982 187.636z"
          ></path>
          <circle
            cx="174.831"
            cy="131.982"
            r="49.696"
            fill={props.color === "white"? "white" : "url(#linearGradient2330)"}
          ></circle>
        </g>
      </svg>
    </div>
  );
}
