import { Container, Logo } from './styled'

import LogoImage from '../../../assets/logoDuxcod3Quadrada.png'

export const Navbar = () => {
	return (
		<Container>
			<a
				href='https://www.duxcod3.com'
				target={'_black'}
			>
				<Logo src={LogoImage}/>
			</a>
		</Container>
	)
}
