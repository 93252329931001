import React from 'react'

import { Container, Content, Title, Number, Text } from './styles'

interface CardHowItWorksProps {
	title: string
	number: number
	text: string
}

const CardHowItWorks = ({ number, text, title }: CardHowItWorksProps) => {
	return (
		<Container>
			<Content>
				<Number>{`0${number}.`}</Number>
				<Title>{title}</Title>
				<Text>{text}</Text>
			</Content>
		</Container>
	)
}

export default CardHowItWorks
