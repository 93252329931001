type props = {
  color: any
}

export default function whatsappIcon(props:props) {
  return (
    <div className="icon">
      <svg
        fill="none"
        version="1.1"
        viewBox="0 0 57 57"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="m48.358 8.3967c-4.7523-4.7495-11.069-7.6102-17.773-8.0497-6.7045-0.43946-13.34 1.5723-18.672 5.6608-5.3316 4.0885-8.9959 9.9751-10.311 16.564-1.3148 6.5889-0.19052 13.431 3.1636 19.253l-3.93 14.344 14.685-3.855c4.0606 2.2138 8.6122 3.3722 13.237 3.369h0.012c7.3701-1e-3 14.442-2.9115 19.678-8.0987 5.2356-5.1872 8.2118-12.232 8.2814-19.601-0.0395-3.6551-0.7993-7.2664-2.2358-10.628-1.4366-3.3612-3.5217-6.4061-6.1362-8.9605l1e-3 2e-3zm-19.587 42.619c-4.1263 0.0036-8.1774-1.1038-11.728-3.206l-0.836-0.5-8.709 2.284 2.321-8.5-0.549-0.869c-1.8414-3.033-2.9583-6.4498-3.2638-9.9848-0.30549-3.5349 0.20865-7.0927 1.5024-10.397 1.2938-3.3038 3.3323-6.2647 5.957-8.6522 2.6247-2.3875 5.7648-4.1373 9.1761-5.1133s7.0017-1.1518 10.492-0.5139 6.7864 2.0725 9.6319 4.1921c2.8455 2.1196 5.1636 4.867 6.7741 8.0286 1.6104 3.1616 2.4698 6.6522 2.5112 10.2-0.065 6.1311-2.5465 11.989-6.9054 16.301-4.3589 4.3122-10.243 6.7305-16.375 6.7297zm12.626-17.242c-0.686-0.349-4.092-2.021-4.729-2.246s-1.1-0.349-1.56 0.349-1.784 2.246-2.2 2.72-0.811 0.524-1.5 0.175c-4.067-2.034-6.737-3.631-9.42-8.234-0.711-1.223 0.711-1.135 2.034-3.78 0.0901-0.1912 0.1317-0.4017 0.1208-0.6128-0.0108-0.2111-0.0736-0.4163-0.1828-0.5972-0.175-0.349-1.56-3.755-2.133-5.14-0.561-1.347-1.135-1.16-1.56-1.185-0.4-0.025-0.861-0.025-1.322-0.025-0.3502 0.0097-0.6947 0.0912-1.0122 0.2393s-0.6012 0.3596-0.8338 0.6217c-0.7818 0.7425-1.4007 1.6395-1.8173 2.6341-0.4166 0.9945-0.6218 2.0648-0.6027 3.1429 0 3.406 2.483 6.7 2.82 7.161s4.884 7.447 11.837 10.454c4.392 1.9 6.113 2.059 8.309 1.734 1.335-0.2 4.092-1.672 4.666-3.294 0.452-1.0355 0.591-2.1805 0.4-3.294-0.167-0.312-0.628-0.486-1.314-0.823z"
          fill={props.color}
        />
        <defs>
          <linearGradient
            id="a"
            x1="28.435"
            x2="28.595"
            y1="55.891"
            y2=".29104"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#6753ff" offset="0" />
            <stop stop-color="#f253ff" offset="1" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
