import styled from 'styled-components'
import { fontsType } from '../../../../styles/fonts'

export const Container = styled.div`
	width: auto;
	height: calc(100vh - (153px));

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 30px;
	gap: 40px;

	color: #fff;
	text-align: center;

	margin-left: 10%;
	margin-right: 10%;

	@media (min-width: 768px) {
		grid-template-columns: repeat(3, 1fr);
		align-items: end;

		padding-bottom: 170px;
	}

	@media (min-width: 280px) and (max-width: 320px) {
		margin-left: auto;
		margin-right: auto;
	}
`

export const Video = styled.video`
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	filter: brightness(0.7);
	z-index: -1;
`
export const WelcomeText = styled.span`
	font-family: ${fontsType.ErbaumMedium};
	font-size: larger;

	z-index: 1;

	@media (min-width: 768px) {
		display: none;
	}
`
export const LeftSide = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	gap: 40px;
	grid-column-start: 0;
	grid-column-end: span 2;
	z-index: 2;
`

export const Text = styled.span`
	font-family: ${fontsType.rubikRegular};
	font-size: large;

	@media (min-width: 280px) and (max-width:320px) {
		font-size: medium;
	}

	@media (min-width: 768px) {
		font-size: x-large;
	}

	@media (min-width: 1240px) {
		font-size: xx-large;
	}
`
export const SubText = styled.span`
	font-family: ${fontsType.rubikRegular};
	font-size: large;

	@media (min-width: 280px) and (max-width:320px) {
		font-size: medium;
	}
`
export const RightSide = styled.div`
	z-index: 2;
	width: 100%;
	display: flex;
	justify-content: center;
`

export const Button = styled.a`
	text-decoration: none;
	width: max(80%, 220px);
	background: linear-gradient(180deg, #f653ff 0%, #7053ff 100%);
	border-radius: 9px;
	outline: none;
	border: none;

	padding: 20px;

	color: white;
	font-family: ${fontsType.rubikBold};
	display: flex;
	justify-content: space-between;

	transition: all 0.5s ease-in-out;

	&:hover {
		color: white;
		transform: scale(1.01);

		box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-webkit-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-moz-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
	}

	&:active {
		color: white;
		transform: scale(0.9);

		box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-webkit-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-moz-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
	}
`

export const Image = styled.img`
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
`

export const ContactButton = styled.button`
	color: #fff;
	font-weight: 500;
	width: 300px;
	min-height: 40px;
	max-height: 50px;
	align-self: center;
	background: linear-gradient(to bottom right, #f653ff, #6653ff);
	border-radius: 1rem;
	margin-top: 40px;
	
	@media (max-device-height: 1000px) and (orientation: portrait) {
		width: 100%;
		font-size: 13px ;
	}
`
