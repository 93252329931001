import { createContext } from "react"

type ContextType = {
  isDarkTheme: boolean
  setIsDarkTheme: (isDarkTheme: boolean) => void
  onTermos: boolean
  setOnTermos: (onTermos: boolean) => void
  onSidebarCadastro: boolean
  setOnSidebarCadastro: (onSidebarCadastro: boolean) => void
}

const Context = createContext<ContextType>({} as ContextType)

export default Context
