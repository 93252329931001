import Markdown from "markdown-to-jsx";
import { useContext, useEffect, useState } from "react";
import { BsArrowReturnLeft } from "react-icons/bs";
import Context from "../../../context";
import {
  BrGradient,
  Button,
  ButtonReturn,
  TermosBackground,
  TermosBody,
  TermosText,
  TermosTitle,
} from "./styles";
const Readme = require("./termos.md");

export const Termos = () => {
  const { setOnTermos } = useContext(Context);

  const [text, setText] = useState("");

  useEffect(() => {
    fetch(Readme)
      .then(function (res) {
        return res.text();
      })
      .then(function (data) {
        setText(data);
      });
  });

  return (
    <TermosBackground>
      <BrGradient>
        <TermosBody>
          <TermosTitle>Termos de Uso & Política de Privacidade</TermosTitle>
          <TermosText>
            <Markdown>{text}</Markdown>
          </TermosText>
          <div className="buttons">
            <ButtonReturn onClick={() => setOnTermos(false)}>
              <BsArrowReturnLeft size={20} />
              <span>VOLTAR A PÁGINA INICIAL</span>
            </ButtonReturn>
            <Button onClick={() => setOnTermos(false)}>
              LI E CONCORDO COM OS TERMOS DE USO E POLÍTICA DE PRIVACIDADE
            </Button>
          </div>
        </TermosBody>
      </BrGradient>
    </TermosBackground>
  );
};
