import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./styles/style.css";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css"
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min"
import FontStyles from "./styles/fonts"

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <FontStyles />
    <App />
  </React.StrictMode>
)
