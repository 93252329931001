export const lightTheme = {
  background: "#f3f4f5",
  titles: "#2C2738",
  text: "#2C2738",
  link: "#aeafaf",
  linkHover: "#555656"
}

export const darkTheme = {
  background: "#100719",
  titles: "#D6E1DF",
  text: "#FFF",
  link: "#BDBDBD",
  linkHover: "#FFF"
}
