import styled from "styled-components";

export const ContactContainer = styled.a`
  height: 85px;
  width: 330px;
  position: relative;
  text-align: center;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: white;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  div {
    width: 56px;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20%;
    justify-content: center;
    background-image: linear-gradient(180deg, #eb5df7 0%, #614ef0 100%);
    margin-left: 10px;

    svg {
      width: 60%;
    }
  }

  .informationDiv {
    height: 100%;
    background: none;
    margin-left: 10px;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    color: #1a1a1a;
  }

  h4 {
    margin-top: 13px;
    font-weight: 500;
    font-size: 1rem;
  }

  p {
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 0.5rem;
    margin-bottom: 0.6rem;
  }

  > * {
    position: relative;
  }

  ::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 18px;
    background: linear-gradient(180deg, #e853ff 0%, #6653ff 100%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  :hover {
    transition-delay: 0.2s;
    transition: 0.3s opacity ease-in-out;

    h4 {
      color: white;
      transition-delay: 0.2s;
      transition: color 0.1s ease-in-out;
    }

    p {
      color: white;
      transition-delay: 0.2s;
      transition: color 0.1s ease-in-out;
    }
    ::before {
      opacity: 1;
    }

    div:nth-of-type(1) {
      background: white;
    }
  }
`;

export const ContactBorder = styled.div`
  height: 93px;
  width: 338px;
  background: linear-gradient(180deg, #eb5df7 0%, #614ef0 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: none;
  margin: 10px;
  user-select: none;
`;
