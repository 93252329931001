import styled from "styled-components";
import { fontsType } from "../../../styles/fonts";

export const CookieContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 10px;
  width: 100vw;
  z-index: 3;
`;
export const CookieBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 50%;
  width: 700px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;

  p {
    margin: 0;
    margin-right: 10px;
  }

  span {
    background-image: linear-gradient(to bottom, #f653ff, #6653ff);
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 12px;
    cursor: pointer;
    font-family: ${fontsType.rubikLight};
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 800px) {
    max-width: 80%;
  }

  @media (max-width: 500px) {
    max-width: 94%;
  }
`;
