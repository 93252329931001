type props = {
    color: any
}

export default function EmailIcon(props: props) {
    return (
        <div className="Icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="53"
                height="53"
                fill="none"
                version="1.1"
                viewBox="0 0 25 23"
            >
                <defs>
                    <linearGradient id="linearGradient858">
                        <stop offset="0" stopColor="#6653ff" stopOpacity="1"></stop>
                        <stop offset="1" stopColor="#f660ff" stopOpacity="1"></stop>
                    </linearGradient>
                    <linearGradient
                        id="linearGradient860"
                        x1="12.513"
                        x2="12.657"
                        y1="22.58"
                        y2="0.837"
                        gradientUnits="userSpaceOnUse"
                        xlinkHref="#linearGradient858"
                    ></linearGradient>
                </defs>
                <path
                    fill={props.color==="white"?"white":"url(#linearGradient860)"}
                    fillOpacity="1"
                    d="M19.155.964H5.396A4.92 4.92 0 00.48 5.879v11.794a4.92 4.92 0 004.915 4.915h13.76a4.92 4.92 0 004.915-4.915V5.879A4.92 4.92 0 0019.155.964zM5.396 2.93h13.76a2.949 2.949 0 012.732 1.855l-7.527 7.528a2.956 2.956 0 01-4.17 0L2.663 4.785A2.949 2.949 0 015.396 2.93zm13.76 17.692H5.396a2.949 2.949 0 01-2.948-2.95V7.353l6.353 6.35a4.921 4.921 0 006.951 0l6.354-6.35v10.32a2.948 2.948 0 01-2.95 2.95z"
                ></path>
            </svg>

        </div>
    );
}
