import styled from "styled-components";
import bg from "../../../../assets/imagens/SocialMediaBackground.svg";
import { fontsType } from "../../../../styles/fonts";

export const AcompanheContainer = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  margin-top: 140px;
  height: 30vw;
  margin-bottom: 20px;

  .container-bg {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 50%
    );
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .acompanhe {
    font-family: ${fontsType.ErbaumMedium};
    letter-spacing: 5px;
  }

  .social-networks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 768px) {
    height: 50vh;
    margin-bottom: 50px;
    .container-bg {
      padding: 50px;
    }
    .social-networks {
      margin-top: 10px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 20px;
    }
  }
  @media (max-width: 600px) {
    margin-bottom: 150px; 
  }
`;
