import { createGlobalStyle } from "styled-components"
import ErbaumBlack from "./fonts/erbaum/Erbaum-Black.ttf"
import ErbaumLight from "./fonts/erbaum/Erbaum-Light.ttf"
import ErbaumMedium from "./fonts/erbaum/Erbaum-Medium.ttf"
import rubikRegular from "./fonts/Rubik-Regular.ttf"
import rubikBold from "./fonts/rubik/Rubik-Bold.ttf"
import rubikLight from "./fonts/rubik/Rubik-Light.ttf"

export const fontsType = {
  ErbaumMedium: "Erbaum Medium",
  ErbaumBlack: "Erbaum Black",
  rubikLight: "Rubik Light",
  rubikRegular: "Rubik Regular",
  rubikBold: "Rubik Bold",
  ErbaumLight: "Erbaum Light"
}

const FontStyles = createGlobalStyle`

@font-face {
    font-family: 'Erbaum Light';
    src: url(${ErbaumLight}) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'Erbaum Medium';
    src: url(${ErbaumMedium}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'Erbaum Black';
    src: url(${ErbaumBlack}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'Rubik Light';
    src: url(${rubikLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
  
  @font-face {
    font-family: 'Rubik Regular';
    src: url(${rubikRegular}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'Rubik Bold';
    src: url(${rubikBold}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }
`

export default FontStyles
