import React, { FormEvent, useRef } from 'react'
import { HiChevronDown, HiChevronRight } from 'react-icons/hi'
import axios from 'axios'

import FormImage from '../../../../assets/imagens/FormImage.png'

import {
	Container,
	ImageSection,
	Image,
	Form,
	Header,
	Title,
	SubTitle,
	InputsSection,
	InputWrapper,
	Label,
	InputContainer,
	UserIcon,
	EmailIcon,
	WhatsAppIcon,
	PencilIcon,
	SelectRoot,
	SelectTrigger,
	SelectValue,
	SelectIcon,
	SelectContent,
	SelectViewport,
	SelectPortal,
	SelectItem,
	SelectItemText,
	SelectItemIndicator,
	SubmitButtonCom,
	ToastProvider,
	ToastRoot,
	FormBorder,
	ToastTitle,
	ToastClose,
	ToastViewport,
} from './styles'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	icon: React.ReactNode
	textArea?: boolean
}

const FormSection = () => {
	const form = useRef<any>('')
	const [isToastOpen, setIsToastOpen] = React.useState(false)
	const [toastMessage, setToastMessage] = React.useState('')
	const [isToastError, setIsToastError] = React.useState(false)
	const [mobile, setMobile] = React.useState({
		value: '(__) _____-____',
		length: 0,
	})

	const phoneHandler = (event: any) => {
		const { value } = event.target
		const onlyNums = value.replace(/[^\d]/g, '')

		if (onlyNums.length > 11) return

		setMobile({
			value: onlyNums.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3'),
			length: onlyNums.length,
		})
	}

	const onHandleClick = async (event: any) => {
		event.preventDefault()

		const formData = new FormData(event.target as HTMLFormElement)
		const data = Object.fromEntries(formData)

		if (
			event.target.name.value === '' ||
			event.target.email.value === '' ||
			event.target.whatsapp.value === '' ||
			event.target.text.value === ''
		) {
			setIsToastError(true)
			setToastMessage('Preencha todos os campos!')
			setIsToastOpen(true)

			setTimeout(() => {
				setIsToastOpen(false)
			}, 3000)

			return
		}

		await axios({
			method: 'post',
			url: 'https://api.emailjs.com/api/v1.0/email/send',
			data: {
				service_id: 'service_wy16ot8',
				template_id: 'template_n7fqjqv',
				user_id: 'vOcNWyZfJ4f5tsOjs',
				template_params: {
					formName: 'Formulário Marketing',
					name: data.name,
					email: data.email,
					whatsapp: data.whatsapp,
					select: data.select,
					text: data.text,
				},
			},
		})
			.then((res) => {
				setToastMessage('Mensagem enviada com sucesso!')
				setIsToastError(false)
				setIsToastOpen(true)
			})
			.catch((err) => {
				setIsToastError(true)
				setToastMessage('Erro ao enviar mensagem!')
				setIsToastOpen(true)
			})

		event.target.name.value = ''
		event.target.email.value = ''
		event.target.whatsapp.value = ''
		event.target.text.value = ''

		setTimeout(() => {
			setIsToastOpen(false)
		}, 5000)
	}

	const SubmitButton = () => {
		return (
			<SubmitButtonCom type='submit'>
				CONFIRMAR
				<HiChevronRight style={{ width: '24px', height: '24px' }} />
			</SubmitButtonCom>
		)
	}

	return (
		<Container id='form'>
			<ToastProvider>
				<ToastRoot
					open={isToastOpen}
					toastError={isToastError}
				>
					<ToastTitle>{toastMessage}</ToastTitle>
				</ToastRoot>
				<ToastViewport />
			</ToastProvider>
			<ImageSection>
				<Title>
					Encontre o profissional de marketing certo para o seu negócio!
				</Title>
				<Image src={FormImage} />
			</ImageSection>
			<FormBorder>
				<Form onSubmit={onHandleClick} id='form-rd'>
					<Header>
						<Title>Fale com a gente!</Title>
						<SubTitle>
							Preencha os campos abaixo e fale com um especilista
						</SubTitle>
					</Header>

					<InputsSection>
						<InputWrapper>
							<Label htmlFor='name'>Seu nome completo</Label>
							<Input
								id={'name'}
								name='name'
								icon={<UserIcon />}
								placeholder='Insira seu nome'
								type='text'
								maxLength={100}
							/>
						</InputWrapper>
						<InputWrapper>
							<Label htmlFor='email'>Seu E-mail</Label>
							<Input
								id={'email'}
								name='email'
								icon={<EmailIcon />}
								placeholder='Insira seu e-mail'
								type='email'
							/>
						</InputWrapper>
						<InputWrapper>
							<Label htmlFor='whatsapp'>Seu Whatsapp</Label>
							<Input
								id={'whatsapp'}
								name='whatsapp'
								icon={<WhatsAppIcon />}
								placeholder='(99) 99999-9999'
								type='tel'
								value={mobile.length > 0 ? mobile.value : ''}
								onChange={phoneHandler}
							/>
						</InputWrapper>
						<InputWrapper>
							<Label htmlFor='select'>Como nos encontrou</Label>
							<SelectRoot name='select'>
								<SelectTrigger id='select'>
									<SelectValue></SelectValue>
									<SelectIcon>
										<HiChevronDown
											style={{
												color: '#F853FF',
												width: '16px',
												height: '16px',
											}}
										/>
									</SelectIcon>
								</SelectTrigger>
								<SelectPortal>
									<SelectContent>
										<SelectViewport>
											<SelectItem value='Indicação de Alguém'>
												<SelectItemText>Indicação de alguém</SelectItemText>
												<SelectItemIndicator />
											</SelectItem>
											<SelectItem value='Instagram'>
												<SelectItemText>Instagram</SelectItemText>
												<SelectItemIndicator />
											</SelectItem>
											<SelectItem value='Facebook'>
												<SelectItemText>Facebook</SelectItemText>
												<SelectItemIndicator />
											</SelectItem>
											<SelectItem value='Youtube'>
												<SelectItemText>Youtube</SelectItemText>
												<SelectItemIndicator />
											</SelectItem>
											<SelectItem value='Pesquisando no google'>
												<SelectItemText>Pesquisando no google</SelectItemText>
												<SelectItemIndicator />
											</SelectItem>
											<SelectItem value='Em um anúncio'>
												<SelectItemText>Em um anúncio</SelectItemText>
												<SelectItemIndicator />
											</SelectItem>
											<SelectItem value='Outro'>
												<SelectItemText>Outro</SelectItemText>
												<SelectItemIndicator />
											</SelectItem>
										</SelectViewport>
									</SelectContent>
								</SelectPortal>
							</SelectRoot>
						</InputWrapper>

						<InputWrapper>
							<Label htmlFor='text'>Como podemos te ajudar</Label>
							<Input
								id={'text'}
								name='text'
								icon={<PencilIcon />}
								placeholder='Escreva uma mensagem'
								type='text'
								textArea
							/>
						</InputWrapper>
					</InputsSection>
					<SubmitButton />
				</Form>
			</FormBorder>
		</Container>
	)
}

export const Input = (props: InputProps) => {
	const { textArea, icon, ...rest } = props
	return (
		<div style={{ position: 'relative', width: '100%' }}>
			{icon}
			<InputContainer
				textArea={textArea}
				{...rest}
			/>
		</div>
	)
}

export default FormSection
