import React from 'react'

import {
	Container,
	Border,
	Content,
	TextWrapper,
	Title,
	Image,
	ButtonText,
	ButtonWrapper,
} from './styles'

import GrowFastImage from '../../../../assets/imagens/growFastImage.png'

const GrowFast = () => {
	return (
		<Container>
			<Border>
				<Content>
					<TextWrapper>
						<Title>Cresça rápido usando o poder da internet.</Title>
						<p>
							Se você quer acelerar o crescimento do seu negócio, precisa atrair
							mais pessoas e ter uma estratégia montada para convertê-los em
							clientes.
						</p>
						<p>E é nisso que vamos te ajudar!</p>
						<p>
							Aumente suas vendas e expanda sua marca com anúncios online no
							Google e Facebook. Nós criamos, monitoramos e otimizamos seus
							anúncios para alcançar o sucesso online ainda hoje!
						</p>
						<Button />
					</TextWrapper>
					<Image src={GrowFastImage} />
				</Content>
			</Border>
		</Container>
	)
}

const Button = () => {
  const handleClick = () => {
    const formComponent = document.getElementById("form");
    if (formComponent) {
      formComponent.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <ButtonWrapper onClick={handleClick}>
      <ButtonText>ENTRAR EM CONTATO</ButtonText>
    </ButtonWrapper>
  );
};

export default GrowFast
