import styled from 'styled-components'
import { fontsType } from '../../../../styles/fonts'

export const Container = styled.div`
	padding: 0 16px;

	@media (min-width: 768px) {
		padding: 0 40px;
		margin-top: 100px;
	}
`

export const Border = styled.div`
	padding: 3px;
	border-radius: 15px;
	background: linear-gradient(180deg, #f653ff 0%, #6653ff 100%);

	@media (min-width: 768px) {
		width: 90%;
		position: relative;
	}
`

export const Content = styled.div`
	background: #fff;
	border-radius: 15px;

	padding: 28px 24px;

	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	gap: 40px;

	@media (min-width: 768px) {
		grid-template-columns: repeat(4, minmax(0, 1fr));
		padding: 40px;
	}

	@media (min-width: 1224px) {
		padding: 40px 80px;
	}
`

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		font-family: ${fontsType.rubikRegular};
		text-align: center;
	}

	@media (min-width: 768px) {
		grid-column: span 3 / span 3;
		align-items: flex-start;

		p {
			text-align: left;
		}
	}

	@media (min-width: 1024px) {
		gap: 16px;

		p {
			font-size: large;
		}
	}
`

export const Title = styled.h1`
	font-family: ${fontsType.ErbaumMedium};
	font-size: large;
	text-align: center;

	@media (min-width: 768px) {
		text-align: left;
	}

	@media (min-width: 1024px) {
		font-size: x-large;
	}

	@media (min-width: 1224px) {
		font-size: xx-large;
	}
`

export const Image = styled.img`
	width: min(100%, 360px);
	margin: 0 auto;

	transition: all 0.2s ease-in-out;

	@media (min-width: 768px) {
		width: 300px;
		position: absolute;
		right: -100px;
		top: 50%;
		transform: translateY(-50%);
	}

	@media (min-width: 1224px) {
		width: 400px;
		transform: translateY(-40%);
	}
`

export const ButtonWrapper = styled.a`
	text-decoration: none;
	outline: none;
	border: none;

	display: flex;
	justify-content: center;

	padding: 8px;
	background: linear-gradient(180deg, #f653ff 0%, #7053ff 100%);
	border-radius: 4px;
	width: 80%;

	transition: all 0.3s ease-in-out;

	&:hover {
		transform: scale(1.01);

		box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-webkit-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-moz-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
	}

	&:active {
		transform: scale(0.99);

		box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-webkit-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
		-moz-box-shadow: 2px 26px 53px 0px rgba(246, 83, 255, 0.35);
	}
`

export const ButtonText = styled.span`
	font-family: ${fontsType.rubikRegular};
	font-weight: 600;
	color: white;
`
