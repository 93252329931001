import React from 'react'
import CardHowItWorks from '../../../../components/components/CardHowItWorks'
import MainText from '../../../../components/elements/MainText'

import { Container, CardsWrapper } from './styles'

const HowItWorks = () => {
	const cards = [
		{
			id: 1,
			title: 'Planejamento',
			text: 'Antes de começar a gerenciar seus anúncios, trabalhamos em conjunto com você para entender suas metas e objetivos. Com base nessas informações, planejamos uma estratégia personalizada para atingir o público-alvo certo e maximizar o retorno sobre o investimento.',
		},
		{
			id: 2,
			title: 'Criação de Anúncios',
			text: 'Com a estratégia definida, criamos os anúncios que serão exibidos no Google e Facebook. Isso envolve a criação de textos, imagens e vídeos, além de selecionar as palavras-chave relevantes e o público-alvo ideal.',
		},
		{
			id: 3,
			title: 'Monitoramento',
			text: 'Depois que os anúncios são publicados, monitoramos de perto seu desempenho. Utilizamos ferramentas de análise de dados para medir o alcance, engajamento e conversão de cada anúncio, ajustando a estratégia de acordo com os resultados.',
		},
		{
			id: 4,
			title: 'Otimização',
			text: 'Com base nos resultados do monitoramento, otimizamos continuamente os anúncios para maximizar o retorno sobre o investimento. Isso pode envolver ajustes nas palavras-chave, público-alvo e orçamento, bem como a criação de novos anúncios com base no desempenho dos anteriores.',
		},
		{
			id: 5,
			title: 'Relatórios e Análise',
			text: 'Finalmente, fornecemos relatórios regulares para que você possa acompanhar o desempenho de seus anúncios e o retorno sobre o investimento. Com essas informações, podemos ajustar a estratégia de acordo com seus objetivos e garantir que seus anúncios online sejam eficazes e gerem resultados.',
		},
	]

	return (
		<Container>
			<MainText
				firstText='Como'
				pinkText='funciona'
				secondText='?'
			/>
			<CardsWrapper>
				{cards.map((card) => (
					<CardHowItWorks
						key={card.id}
						number={card.id}
						title={card.title}
						text={card.text}
					/>
				))}
			</CardsWrapper>
		</Container>
	)
}

export default HowItWorks
