import React from 'react'
import MainText from '../../../../components/elements/MainText'

import { Container, Content, Image, TextWrapper } from './styles'

import BillingImage from '../../../../assets/imagens/BillingImage.png'

const Billing = () => {
	return (
		<Container>
			<MainText
				firstText='Está satisfeito com os seus'
				pinkText={'resultados'}
				secondText='?'
			/>
			<Content>
				<Image
					src={BillingImage}
					alt='BillingImage'
				/>
				<TextWrapper>
					<p>
						Nosso principal objetivo é garantir que o seu negócio cresça
						constantemente, e por isso, buscamos estratégias para aumentar suas
						venda. O “novo marketing” é uma ótima forma de transformar sua
						empresa em uma máquina de fazer dinheiro pela internet e atingir um
						público maior.
					</p>
					<p>Você precisa de mais vendas? Nós temos a solução!</p>
				</TextWrapper>
			</Content>
		</Container>
	)
}

export default Billing
