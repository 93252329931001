import styled from "styled-components";
import { fontsType } from "../../../styles/fonts";
import { Theme } from "../../../theme";

interface Props {
  isDarkTheme?: boolean;
}

export const FooterContainer = styled.div<Props>`
  margin-bottom: 50px;

  padding: 0 150px;

  .footer-img-container img {
    max-height: 40px;
    margin-right: 10px;
  }

  .menu-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: ${fontsType.rubikRegular};
  }

  .list-menu {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    li {
      margin-right: 10px;
    }
  }

  .termos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: ${fontsType.rubikRegular};
    margin-top: 40px;
    color: ${(props) => (props.isDarkTheme ? Theme.dark : Theme.light).text};
  }

  @media (max-width: 992px) {
    .menu-footer {
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    margin-top: 100px;

    .termos {
      flex-direction: column;
      max-width: 100%;
      text-align: center;
      span {
        margin-bottom: 10px;
      }
      .politicas {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        font-family: ${fontsType.rubikRegular}
      }
    }
  }
`;

interface CardContainerProps {
  bgColor: string;
}

const selectColor = (bgColor: string) => {
  // "white" | "dark";
  switch (bgColor) {
    case "white":
      return "#fff";
    case "dark":
      return "#251D64";
  }
};

export const CardContainer = styled.div<CardContainerProps>`
  background-image: linear-gradient(to bottom, #f653ff, #6653ff);
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  min-width: 400px;
  margin-right: 20px;
  margin-top: 20px;

  .card-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 15px;
    background-color: ${(props) => selectColor(props.bgColor)};
    width: 100%;
    height: 100%;
    border-radius: 15px;
    min-height: 100px;
    transition: 0.3s;
    color: ${(props) =>
      (props.bgColor !== "white" ? Theme.dark : Theme.light).text};
  }

  .card-container-gradient {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 15px;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    min-height: 100px;
    color: #fff;
    span {
      display: block;
    }
  }

  img {
    height: 70px;
    width: 70px;
    margin-right: 10px;
  }
  h3 {
    margin: 0;
    font-family: ${fontsType.rubikRegular};
    font-size: 18px;
  }
  p {
    margin: 0;
    font-family: ${fontsType.rubikLight};
    font-size: 13px;
  }

  @media (max-width: 425px) {
    min-width: 320px;
    margin-right: 0px;

    p {
      word-wrap: break-word;
      width: 180px;
    }
  }
`;

export const FooterAddress = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  
  align-items: center;
  margin-top: 50px;
  width: 100%;

  a {
    color: #000;
    text-decoration: none;
    transition: 0.3s;
    max-width: 100%;
  }

  @media (max-width: 992px) {
    margin-top: 20px;
    justify-content: center;
  }
`;
