import styled from 'styled-components'

export const Container = styled.div`
	width: 100%;
	padding: 31px 13px;
	display: flex;
	flex-direction: column;
	//align-items: center;

	@media (min-width: 768px) {
		padding: 50px;
	}

	@media (max-width: 767px) {
		align-items: center;
	}
`

export const Logo = styled.img`
	margin-left: 3rem;
	width: 20rem;
	height: auto;
	z-index: 101;

	@media (max-width: 767px) {
		margin-left: 0px;
		width: 18rem;
	}

	@media (max-width: 300px) {
		margin-left: 0px;
		width: 13rem;
	}
`