import styled from 'styled-components'

export const Container = styled.div`
	margin-top: 40px;

	display: flex;
	flex-direction: column;

	align-items: center;

	gap: 28px;

	@media (min-width: 768px) {
		margin-top: 100px;
	}

`

export const CardsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));

	place-items: center;

	gap: 20px;

	@media (min-width: 768px) {
		padding: 10px;
		width: min(100%, 1000px);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	@media (min-width: 1024px) {
	}
`
