import { useEffect, useRef } from 'react'

import {
	Container,
	Video,
	LeftSide,
	Text,
	SubText,
	ContactButton,
} from './styles'

import InitialVideo from '../../../../assets/videos/initialVideo.mp4'

const Initial = () => {
	const videoRef = useRef<HTMLVideoElement>(null)

	const scrollToContact = () => {
		const contact = document.getElementById('form-rd')
		contact?.scrollIntoView({ behavior: 'smooth' })
	}

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]: IntersectionObserverEntry[]) => {
				if (!entry.isIntersecting) {
					videoRef.current?.pause()
					console.log('pause')
				} else {
					videoRef.current?.play()
					console.log('play')
				}
			},
			{ threshold: 0.25 }
		)
		if (videoRef.current) {
			observer.observe(videoRef.current)
		}
		return () => observer.disconnect()
	}, [])

	return (
		<Container>
			<Video
				ref={videoRef}
				src={InitialVideo}
				muted
				autoPlay
				loop
			></Video>

			<LeftSide>
				<Text>Cresça rápido usando o poder da internet!</Text>
				<SubText>
					Com mais de 10 anos no mercado atendendo empresas em toda a América
					Latina, Estados Unidos, Portugal e Espanha, somos líder quando se fala
					em soluções tecnológicas.
				</SubText>
				<ContactButton onClick={scrollToContact}>
					Fale com Nosso Especialista!
				</ContactButton>
			</LeftSide>
		</Container>
	)
}

export default Initial
