type props = {
  color: any,
}

export default function FacebookIcon(props:props) {
  return (
    <div className="icon">
      <svg fill="none" viewBox="0 0 59 59" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m58.6 17.397c-0.219-4.633-1.278-8.737-4.672-12.118s-7.485-4.44-12.118-4.672c-4.775-0.271-19.087-0.271-23.862 0-4.62 0.219-8.724 1.278-12.118 4.659s-4.44 7.485-4.67 12.118c-0.273 4.775-0.273 19.087 0 23.862 0.217 4.633 1.276 8.737 4.67 12.12s7.485 4.439 12.118 4.672c4.775 0.271 19.087 0.271 23.862 0 4.633-0.219 8.737-1.278 12.118-4.672s4.439-7.485 4.672-12.118c0.271-4.775 0.271-19.074 0-23.849v-2e-3zm-6.169 28.969c-0.4908 1.2448-1.2323 2.3754-2.1785 3.3215-0.9461 0.9462-2.0767 1.6877-3.3215 2.1785-3.807 1.51-12.841 1.161-17.048 1.161s-13.251 0.339-17.046-1.161c-1.2448-0.4908-2.3754-1.2323-3.3216-2.1785-0.94616-0.9461-1.6876-2.0767-2.1784-3.3215-1.507-3.8-1.159-12.837-1.159-17.045s-0.335-13.255 1.162-17.048c0.49078-1.2448 1.2323-2.3754 2.1784-3.3216 0.9462-0.94615 2.0768-1.6876 3.3216-2.1784 3.804-1.507 12.838-1.159 17.045-1.159s13.254-0.335 17.048 1.162c1.2448 0.49078 2.3754 1.2323 3.3215 2.1784 0.9462 0.94616 1.6877 2.0768 2.1785 3.3216 1.51 3.807 1.161 12.841 1.161 17.048s0.347 13.251-1.163 17.042z"
          fill={props.color}
        />
        <path
          d="m38.257 31.571 1-6.515h-6.252v-4.228c0-1.782 0.873-3.52 3.673-3.52h2.842v-5.547c-1.6684-0.2695-3.3542-0.4165-5.044-0.44-5.146 0-8.514 3.12-8.514 8.769v4.965h-5.723v6.516h5.723v15.75h7.043v-15.75h5.252z"
          fill={props.color}
        />
        <defs>
          <linearGradient
            id="b"
            x1="29.879"
            x2="29.879"
            y1=".40332"
            y2="58.241"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F653FF" offset="0" />
            <stop stop-color="#6653FF" offset="1" />
          </linearGradient>
          <linearGradient
            id="a"
            x1="29.879"
            x2="29.879"
            y1="11.321"
            y2="47.321"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F653FF" offset="0" />
            <stop stop-color="#6653FF" offset="1" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
