import { useContext, useState } from 'react'
import logoNameDuxcod3 from '../../../assets/logoDuxcod3Hor.png'
import Context from '../../../context'
import ContactButtons from '../../elements/ContactButtons'
import { FooterAddress, FooterContainer } from './styles'
import WhatsappIcon from '../../elements/icons/WhatsappIcon'
import GpsIcon from '../../elements/icons/GpsIcon'
import EmailIcon from '../../elements/icons/EmailIcon'

export const Footer = () => {
	const { setOnTermos, isDarkTheme } = useContext(Context)
	const [whatsappColor, setWhatsappColor] = useState('white')
	const [emailColor, setEmailColor] = useState('white')
	const [gpsColor, setGpsColor] = useState('white')

	return (
		<FooterContainer isDarkTheme={isDarkTheme}>
			<div className='menu-footer'>
				<div className='footer-img-container'>
					<img
						src={logoNameDuxcod3}
						alt='logo DUXCOD3'
					/>
				</div>
			</div>
			<FooterAddress>
				<ContactButtons
					title='Endereço'
					text1='Rua Andaluzita, 131, 9° andar, Carmo'
					text2='Belo Horizonte - MG'
					onHover={setGpsColor}
				>
					<GpsIcon color={gpsColor} />
				</ContactButtons>
				<ContactButtons
					href='mailto: comercial@duxcod3.com'
					title='E-Mail'
					text1='comercial@duxcod3.com'
					onHover={setEmailColor}
				>
					<EmailIcon color={emailColor} />
				</ContactButtons>
				<ContactButtons
					href='https://api.whatsapp.com/send?phone=5531985217476&text=Olá.%20Vi%20o%20site%20da%20DUXCOD3,%20aguardo%20mais%20informações.'
					title='Whatsapp'
					text1='+ 55 (31) 98521-7476'
					onHover={setWhatsappColor}
				>
					<WhatsappIcon color={whatsappColor} />
				</ContactButtons>
			</FooterAddress>
			<div className='termos'>
				<span>COPYRIGHT © 2023 DUXCOD3</span>
				<div className='politicas'>
					<span
						style={{ cursor: 'pointer', textDecoration: 'none' }}
						onClick={() => setOnTermos(true)}
					>
						TERMOS DE USO E POLÍTICA DE PRIVACIDADE
					</span>
				</div>
			</div>
		</FooterContainer>
	)
}
