type props = {
  color: any
}

export default function LinkedinIcon(props:props) {
  return (
    <div className="icon">
      <svg fill="none" viewBox="0 0 59 59" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m38.922 42.673v-10.512c0-2.641-0.945-4.443-3.309-4.443-0.7371 4e-3 -1.4549 0.2357-2.0553 0.6633-0.6004 0.4277-1.054 1.0304-1.2987 1.7257-0.1674 0.5133-0.2406 1.0527-0.216 1.592v10.974h-6.537s0.088-17.8 0-19.647h6.536v2.784c-0.014 0.022-0.03 0.043-0.044 0.065h0.044v-0.065c0.5915-1.0277 1.4532-1.8737 2.4915-2.4461 1.0384-0.5725 2.2138-0.8494 3.3985-0.8009 4.3 0 7.524 2.809 7.524 8.844v11.266h-6.534zm-23.563 0v-19.648h6.533v19.648h-6.533zm3.224-22.328c-0.4604 0.0171-0.9195-0.0596-1.3494-0.2254s-0.8216-0.4173-1.1513-0.7391c-0.3297-0.3219-0.5906-0.7074-0.7668-1.1331-0.1761-0.4258-0.2639-0.8829-0.258-1.3436s0.1054-0.9154 0.2924-1.3365 0.4577-0.7998 0.7955-1.1131c0.3379-0.3133 0.7359-0.5546 1.1699-0.7093 0.434-0.1548 0.8949-0.2197 1.3547-0.1909 0.4686-0.0337 0.939 0.0298 1.3818 0.1866 0.4428 0.1567 0.8483 0.4034 1.1912 0.7245s0.6156 0.7096 0.8011 1.1412c0.1855 0.4315 0.2797 0.8968 0.2767 1.3665-0.0029 0.4697-0.103 0.9337-0.294 1.3629-0.1909 0.4292-0.4686 0.8142-0.8155 1.1309s-0.7555 0.5583-1.2003 0.7094c-0.4447 0.1512-0.9159 0.2087-1.384 0.169h-0.044z"
          fill={props.color}
        />
        <path
          d="m58.6 17.397c-0.219-4.633-1.278-8.737-4.672-12.118s-7.485-4.44-12.118-4.672c-4.775-0.271-19.087-0.271-23.862 0-4.62 0.219-8.724 1.278-12.118 4.659s-4.44 7.485-4.67 12.118c-0.273 4.775-0.273 19.087 0 23.862 0.217 4.633 1.276 8.737 4.67 12.12s7.485 4.439 12.118 4.672c4.775 0.271 19.087 0.271 23.862 0 4.633-0.219 8.737-1.278 12.118-4.672s4.439-7.485 4.672-12.118c0.271-4.775 0.271-19.074 0-23.849v-2e-3zm-6.169 28.969c-0.4907 1.2448-1.2322 2.3754-2.1784 3.3215-0.9461 0.9462-2.0767 1.6877-3.3216 2.1785-3.807 1.51-12.841 1.161-17.048 1.161s-13.251 0.339-17.046-1.161c-1.2448-0.4908-2.3754-1.2323-3.3215-2.1785-0.94615-0.9461-1.6876-2.0767-2.1784-3.3215-1.507-3.8-1.159-12.837-1.159-17.045s-0.335-13.255 1.162-17.048c0.49078-1.2448 1.2323-2.3754 2.1784-3.3216 0.94613-0.94615 2.0767-1.6876 3.3215-2.1784 3.804-1.507 12.838-1.159 17.045-1.159s13.254-0.335 17.048 1.162c1.2448 0.49078 2.3754 1.2323 3.3216 2.1784 0.9462 0.94616 1.6877 2.0768 2.1784 3.3216 1.51 3.807 1.161 12.841 1.161 17.048s0.347 13.251-1.163 17.042z"
          fill={props.color}
        />
        <defs>
          <linearGradient
            id="b"
            x1="30.257"
            x2="30.257"
            y1="13.545"
            y2="42.673"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F253FF" offset="0" />
            <stop stop-color="#6653FF" offset="1" />
          </linearGradient>
          <linearGradient
            id="a"
            x1="29.88"
            x2="29.88"
            y1=".40332"
            y2="58.241"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F253FF" offset="0" />
            <stop stop-color="#6653FF" offset="1" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
