import React from 'react'
import styled from 'styled-components'
import MainText from '../../../../components/elements/MainText'
import { fontsType } from '../../../../styles/fonts'

const Potential = () => {
	return (
		<Container>
			<MainText
				pinkText='Acreditamos'
				secondText='no potencial das pessoas'
			/>
			<p>
				E sabemos que o resultado vem de uma soma: conhecimento + tecnologia.
				Pensando nisso que unimos pessoas e processos da maneira mais eficiente
				possível.
			</p>
		</Container>
	)
}

const Container = styled.div`
	padding: 0 20px;
	margin-top: 90px;
	display: flex;
	flex-direction: column;
	align-items: center;
	p {
		text-align: center;
		font-family: ${fontsType.ErbaumLight};
		font-size: small;
	}

	@media (min-width: 768px) {
    padding: 0 40px;
    p {
      font-size: medium;
    }
	}
`

export default Potential
