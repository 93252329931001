import { useEffect, useState } from "react";
import { CookieBottom, CookieContainer } from "./styles";

export const Cookie = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // verifica se o cookie já foi criado no localStorage
    if (localStorage.getItem("cookie")) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);

  const handleCookie = () => {
    localStorage.setItem("cookie", "true");
    setShow(false);
  };

  return (
    <>
      {show ? (
        <CookieContainer>
          <CookieBottom>
            <p>
              Este site usa cookies Ao navegar no nosso site você aceita o uso
              de cookies para personalizar sua experiência de acordo com a
              Declaração de Privacidade.
            </p>
            <span onClick={handleCookie}>
              Aceitar
              <br />
              <strong>Cookies</strong>
            </span>
          </CookieBottom>
        </CookieContainer>
      ) : null}
    </>
  );
};
