import styled from 'styled-components'
import { fontsType } from '../../../../styles/fonts'

export const Container = styled.div`
	margin-top: 100px;
	padding: 20px;

	display: flex;
	flex-direction: column;

	gap: 25px;

	@media (min-width: 768px) {
		padding: 60px;
		gap: 60px;
	}
`

export const Content = styled.div`
	display: grid;
	grid-template-columns: repeat(1, minmax(0, 1fr));

	grid-gap: 60px;

	@media (min-width: 768px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-gap: 60px;
		align-items: center;
	}
`

export const Image = styled.img`
	width: min(100%, 460px);
	margin: 0 auto;

	@media (min-width: 768px) {
		width: min(100%, 550px);
	}

	@media (min-width: 1550px) {
		width: min(100%, 650px);
	}
`

export const TextWrapper = styled.div`
	> p {
		text-align: center;
		font-family: ${fontsType.rubikRegular};

		@media (min-width: 768px) {
			text-align: left;
			font-size: large;
		}
	}
`
