import { useContext, useEffect } from 'react'
import { Footer } from '../../components/components/footer'
import { Navbar } from '../../components/components/navbar'
import { ScrowTo } from '../../components/elements/scrowTo'
import { Termos } from '../../components/components/termos'
import Context from '../../context'

import { StylesContainer } from './style'
import Initial from './sections/initial'
import { Acompanhe } from './sections/acompanhe'
import Billing from './sections/Billing'
import GrowFast from './sections/GrowFast'
import HowItWorks from './sections/HowItWorks'
import Potential from './sections/Potential'
import FormSection from './sections/formSection'

export const Empresas = () => {
	const { isDarkTheme, setIsDarkTheme, onTermos } = useContext(Context)

	useEffect(() => {
		setIsDarkTheme(false)
	}, [isDarkTheme, setIsDarkTheme])

	return (
		<StylesContainer>
			{onTermos && <Termos />}
			<Navbar />
			<Initial />
			<Billing />
			<GrowFast />
			<HowItWorks />
			<Potential />
			<FormSection />
			<Acompanhe />
			<Footer />
		</StylesContainer>
	)
}
