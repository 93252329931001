import Logo from "../../assets/imagens/Logo.svg";

export const Page404 = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
      }}
      className="App"
    >
      <div
        style={{
          writingMode: "vertical-rl",
          textOrientation: "mixed",
          position: "absolute",
          left: "40vw",
          top: "20vh",
          paddingLeft: 0,
          transform: "skewX(21.5deg) rotateZ(-45deg) skewY(21.5deg)",
          opacity: 0.05
        }}
      >
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
        404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404 404
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: 340,
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
          textAlign: "center"
        }}
      >
        <img alt="logo" src={Logo} width={100} />
        <h2 style={{ fontWeight: 400, fontSize: 18, color: "black" }}>
          Oops, que tal voltar para o site da DUXCOD3?{" "}
        </h2>
        <p style={{ fontSize: 14, lineHeight: "21px" }}></p>
        <p style={{ fontSize: 14, lineHeight: "21px" }}></p>
      </div>
    </div>
  )
}
