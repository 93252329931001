import styled from 'styled-components'; 

export const SocialMediaContainer = styled.a`
  margin: 30px;
  height: 130px;
  width: 130px; 
  border-radius: 50%;
  background: #FFFFFF;
	filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
  cursor: pointer;
  position: relative;

  ::before {
    content: ''; 
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(180deg, #E853FF 0%, #6653FF 100%); 
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  svg {
    width: 50%;
    height: 50%;
  }

  div {
    display: flex;
    justify-content:center;
    align-items: center;
  }

  div:nth-of-type(2){
    display: none;
  }

  display:flex;
  justify-content:center;
  align-items: center;

  :hover {
    transition-delay: 120ms;
    transition: 300ms ease-in all;
    ::before {
      transition: opacity 0.7s;
      opacity : 1;
    }
    
  }

  > * {
    position: relative;
  }

  @media (max-width: 768px) {
    margin: 10px;
  }
  @media (max-width: 600px) {
    margin: 5px;
    margin-top: 3px;
    height: 100px; 
    width: 100px;
  }
  @media (max-width: 364px) {
    margin: 3px;
  }
`
