import { useState } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { Cookie } from "./components/components/cookie";
import Context from "./context";
import { Rota } from "./routes";
import { darkTheme, lightTheme } from "./styles/theme";
import { Theme } from "./theme";

function App() {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [onTermos, setOnTermos] = useState(false);
  const [onSidebarCadastro, setOnSidebarCadastro] = useState(false);

  return (
    <>
      <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
        <GlobalStyle isDarkTheme={isDarkTheme} />
        <Context.Provider
          value={{
            isDarkTheme,
            setIsDarkTheme,
            onTermos,
            setOnTermos,
            onSidebarCadastro,
            setOnSidebarCadastro,
          }}
        >
          <Rota />
          <Cookie />
        </Context.Provider>
      </ThemeProvider>
    </>
  );
}

interface GlobalStyleProps {
  isDarkTheme?: boolean;
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  body {
    background: ${(props) =>
      props.isDarkTheme ? Theme.dark.background : Theme.light.background};
  }
`;

export default App;
