export const Theme = {
  light: {
    text: "#1a1a1a",
    background: "#fff",
  },
  dark: {
    text: "#fafafa",
    background: "linear-gradient(to bottom, #261E65, #060234)",
  },
};
